document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('.JumbotronClaim--carousel .swiper')
    .forEach(async (container) => {
      const { tns } = await import('tiny-slider/src/tiny-slider')
      const numSlides = container.querySelectorAll(
        '.JumbotronClaim__slide',
      ).length

      tns({
        container,
        preventScrollOnTouch: 'auto',
        items: 1,
        loop: true,
        mouseDrag: true,
        swipeAngle: 30,
        slideBy: 'page',
        controls: false,
        autoplay: numSlides > 1,
        autoplayTimeout: 7000,
        autoplayButtonOutput: false,
        nav: numSlides > 1,
        navPosition: 'bottom',
      })
    })

  document
    .querySelectorAll('.JumbotronClaim--gallery .swiper')
    .forEach(async (container) => {
      const { tns } = await import('tiny-slider/src/tiny-slider')
      const numSlides = container.querySelectorAll(
        '.JumbotronClaim__slide',
      ).length

      tns({
        container,
        preventScrollOnTouch: 'auto',
        items: 1,
        loop: true,
        mouseDrag: true,
        swipeAngle: 30,
        slideBy: 'page',
        controls: false,
        autoplay: numSlides > 1,
        autoplayTimeout: 7000,
        autoplayButtonOutput: false,
        nav: numSlides > 1,
        mode: 'gallery',
        navPosition: 'bottom',
      })
    })

  document
    .querySelectorAll('.IconShortText:not(.IconShortText--grid-mode)')
    .forEach(async (container) => {
      const { tns } = await import('tiny-slider/src/tiny-slider')
      const slider = tns({
        container: container.querySelector('.swiper')!,
        preventScrollOnTouch: 'auto',
        items: 1,
        swipeAngle: 30,
        center: true,
        lazyload: true,
        lazyloadSelector: '.tns-lazy-img',
        loop: window.innerWidth < 992,
        autoWidth: true,
        slideBy: 'page',
        controls: true,
        prevButton: container.querySelector(
          '.IconShortText__prev',
        ) as HTMLElement,
        nextButton: container.querySelector(
          '.IconShortText__next',
        ) as HTMLElement,
        navContainer: container.querySelector(
          '.IconShortText__nav',
        ) as HTMLElement,
        disable: false,
        responsive: {
          992: {
            disable: true,
          },
        },
      })

      slider?.events.on('indexChanged', (info) => {
        const textNavIndex = container.querySelector(
          '.IconShortText__textnav__index',
        ) as HTMLElement
        if (textNavIndex) {
          // @ts-ignore
          textNavIndex.innerText = info.displayIndex
        }
      })
    })
  document.querySelectorAll('.LineComparison').forEach(async (container) => {
    const { tns } = await import('tiny-slider/src/tiny-slider')
    tns({
      container: container.querySelector('.swiper')!,
      preventScrollOnTouch: 'auto',
      items: 1,
      swipeAngle: 30,
      loop: false,
      center: true,
      autoWidth: true,
      slideBy: 'page',
      controls: true,
      nav: false,
      // @ts-ignore
      viewportMax: true,
      prevButton: container.querySelector(
        '.LineComparison__prev',
      ) as HTMLElement,
      nextButton: container.querySelector(
        '.LineComparison__next',
      ) as HTMLElement,
      disable: false,
      responsive: {
        768: {
          disable: true,
          center: false,
        },
      },
    })
    container
      .querySelector('.js-details-btn')
      ?.addEventListener('click', (e) => {
        const svg = (e.currentTarget as HTMLElement).querySelector('svg use')
        svg?.setAttribute(
          'xlink:href',
          svg?.getAttribute('xlink:href') === '#plus' ? '#minus' : '#plus',
        )
        container.classList.toggle('LineComparison--collapsed')
      })
  })
  
  document.querySelectorAll('.Statement-icon-numeration').forEach(async (container) => {
    const { tns } = await import('tiny-slider/src/tiny-slider')
    tns({
      container: container.querySelector('.swiper')!,
      preventScrollOnTouch: 'auto',
      items: 1,
      swipeAngle: 30,
      loop: false,
      center: true,
      autoWidth: true,
      // @ts-ignore
      viewportMax: true,
      fixedWidthViewportWidth: 100,
      gutter: 10,
      slideBy: 'page',
      controls: true,
      nav: true,
      navPosition: 'bottom',
      prevButton: container.querySelector(
        '.Statement-icon-numeration__prev',
      ) as HTMLElement,
      nextButton: container.querySelector(
        '.Statement-icon-numeration__next',
      ) as HTMLElement,
      disable: false,
      responsive: {
        768: {
          disable: true,
          center: false,
        },
      },
    })
  })

})
