// Make header compact on scroll
document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  on('click', '.ContactOverlay', (e) => {
    if (e.target === e.currentTarget) {
      document
        .querySelector('.ContactOverlay')!
        .classList.remove('ContactOverlay--active')
    }
  })
  document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') {
      document
        .querySelector('.ContactOverlay')
        ?.classList.remove('ContactOverlay--active')
    }
  })
})
