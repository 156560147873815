document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  const modal = document.querySelector('#popupBanner') as HTMLElement

  if (modal) {
    const popUpBannerStorageName =
      modal.getAttribute('data-banner-id') || 'popUpBanner'

    const showAlways = modal.getAttribute('data-banner-show-always')

    if (showAlways) {
      modal.style.setProperty('display', 'flex')
    } else if (!sessionStorage.getItem(popUpBannerStorageName)) {
      sessionStorage.setItem(popUpBannerStorageName, 'true')
      modal.style.setProperty('display', 'flex')
    }
  }

  on('click', '.PopupBanner__close', () => {
    modal?.style.setProperty('display', 'none')
  })
})
